$(function() {
  // for tab functionality see mwng-gallery-tabs.js
  $('.mwng-gallery__nav-link').on('mouseenter', function(e) {
    var id = $(this).attr('data-id');
    $(this).closest('.mwng-gallery__gallery').removeClass (function (index, className) {
      return (className.match (/(^|\s)mwng-gallery__gallery--inactive-\S+/g) || []).join(' ');
    });
    $(this).closest('.mwng-gallery__gallery').addClass('mwng-gallery__gallery--inactive-' + id);
  });

  $('.mwng-gallery__nav-link').on('mouseout', function(e) {
    var id = $(this).attr('data-id');
    $(this).closest('.mwng-gallery__gallery').removeClass('mwng-gallery__gallery--inactive-' + id);
  });

  $('.mwng-gallery__arrow--next').on('click', function(e) {
    var $currentCategory = $(this).closest('.mwng-gallery__section-inner');
    var $nextCategory = $(this).closest('.mwng-gallery__gallery').find('[aria-selected="true"]').closest('.mwng-gallery__nav-item').next();
    var $activeProduct = $currentCategory.find('[aria-selected="true"]');

    // If there are no active products in the opened category
    if ($activeProduct.length == 0) {
      // FE-TODO: Remove logging when no longer useful:
      // console.log('next', "First product");

      // Click the button of the first product in the category.
      $currentCategory.find('.mwng-gallery__pager-nav-item:first').find('.mwng-gallery__pager-link').click();
    }
    else {
      var $nextProduct = $activeProduct.closest('.mwng-gallery__pager-nav-item').next();

      // If there is no another product in this category
      if ($nextProduct.length) {
        // FE-TODO: Remove logging when no longer useful:
        // console.log('next', "Next product");

        // Click the button of the *next* product.
        $nextProduct.find('.mwng-gallery__pager-link').click();
      }
      else {
        // Click the currently active category to unselect it.
        $activeProduct.click();

        // If there is a category after the current one.
        if ($nextCategory.length) {
          // FE-TODO: Remove logging when no longer useful:
          // console.log('next', "Next category");

          // Click the button of the *next* category.
          $nextCategory.find('.mwng-gallery__nav-link').addClass('mwng-gallery__nav-link--no-transition').click();
        }
        else {
          // FE-TODO: Remove logging when no longer useful:
          // console.log('next', "Wrap around to first category");

          // Click the button of the first category.
          $(this).closest('.mwng-gallery__gallery').find('.mwng-gallery__nav-item:first').find('.mwng-gallery__nav-link').click();
        }
      }
    }
  });

  $('.mwng-gallery__arrow--prev').on('click', function(e) {
    var $currentCategory = $(this).closest('.mwng-gallery__section-inner');
    var $prevCategory = $(this).closest('.mwng-gallery__gallery').find('[aria-selected="true"]').closest('.mwng-gallery__nav-item').prev();
    var $activeProduct = $currentCategory.find('[aria-selected="true"]');

    // If there are no active products in the opened category
    if ($activeProduct.length == 0) {
      var $targetCategory;

      // If a previous category exists, that is the new target.
      if ($prevCategory.length) {
        // FE-TODO: Remove logging when no longer useful:
        // console.log('prev', "Previous category");
        $targetCategory = $prevCategory.find('.mwng-gallery__nav-link');
      }
      // Otherwise, the new target is the last category in the list.
      else {
        // FE-TODO: Remove logging when no longer useful:
        // console.log('prev', "Wrap around to first category");
        $targetCategory = $(this).closest('.mwng-gallery__gallery').find('.mwng-gallery__nav-item:last').find('.mwng-gallery__nav-link');
      }

      // Click the new target category.
      $targetCategory.click();
      $targetSection = $('#' + $targetCategory.attr('aria-controls'));

      // FE-TODO: Remove logging when no longer useful:
      // console.log('prev', "Last product");

      // Click the button of the last product in the category.
      $targetSection.find('.mwng-gallery__pager-nav-item:last').find('.mwng-gallery__pager-link').click();
    }
    else {
      var $prevProduct = $activeProduct.closest('.mwng-gallery__pager-nav-item').prev();

      // If there is no another product in this category
      if ($prevProduct.length) {
        // FE-TODO: Remove logging when no longer useful:
        // console.log('prev', "Previous product");

        // Click the button of the *previous* product.
        $prevProduct.find('.mwng-gallery__pager-link').click();
      }
      else {
        // FE-TODO: Remove logging when no longer useful:
        // console.log('prev', "De-select first product");

        // Click the currently active category to unselect it.
        $activeProduct.click();
      }
    }
  });

  // Mobile
  $('.mwng-mobile-gallery__accordion-button').on('click', function(e){
    var id = '#' + $(this).attr('aria-controls');

    if ($(this).attr('aria-selected') == 'true') {
      $(this).attr('aria-selected', false);
      $(id).removeClass('mwng-mobile-gallery__accordion-content--active');
    }
    else {
      $(this).attr('aria-selected', true);
      $(id).addClass('mwng-mobile-gallery__accordion-content--active');

      // Scroll to top of category.
      var rectStickyHeader = document.querySelector('#header').getBoundingClientRect();
      var navWrapper = $(this).closest('.mwng-mobile-gallery__nav-item');
      var scrollTop = $(navWrapper).offset().top - rectStickyHeader.height;
      $('html, body').animate({scrollTop: scrollTop}, 500);
    }
  });


  // mobile carousel
  var carousels = document.querySelectorAll('.mwng-mobile-gallery__subsection-gallery-wrapper');
  carousels.forEach((carousel) => {
    var indicator = carousel.querySelector('.mwng-mobile-gallery__dots');
    var elements = carousel.querySelectorAll('.mwng-mobile-gallery__subsection-gallery > *');
    var currentIndex = 0;
    function renderIndicator() {
      // this is just an example indicator; you can probably do better
      indicator.innerHTML = '';
      for (var i = 0; i < elements.length; i++) {
        var button = document.createElement('button');
        button.innerHTML = (i === currentIndex ? '<span class="mwng-mobile-gallery__dot--active">' : '<span class="mwng-mobile-gallery__dot--inactive">');
        (function(i) {
          button.onclick = function() {
            elements[i].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
          }
        })(i);
        indicator.appendChild(button);
      }
    }

    var observer = new IntersectionObserver(function(entries, observer) {
      // find the entry with the largest intersection ratio
      var activated = entries.reduce(function (max, entry) {
        return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
      });
      if (activated.intersectionRatio > 0) {
        currentIndex = elementIndices[activated.target.getAttribute("id")];
        renderIndicator();
      }
    }, {
      root:carousel, threshold:0.5
    });
    var elementIndices = {};
    for (var i = 0; i < elements.length; i++) {
      elementIndices[elements[i].getAttribute("id")] = i;
      observer.observe(elements[i]);
    }
  });
});
