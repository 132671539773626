$(function() {
  $('.partner-product__pager-button').on('click', function(e){
    var $wrapper = $(this).closest('.partner-product');
    var index = $(this).closest('li').index();

    $wrapper.find('.partner-product__pager-button').removeClass('partner-product__pager-button--active');
    $(this).addClass('partner-product__pager-button--active');
    $wrapper.find('.partner-product__gallery-main-image').removeClass('partner-product__gallery-main-image--active');
    $wrapper.find('.partner-product__gallery-main-image').eq(index).addClass('partner-product__gallery-main-image--active');
  });
});
