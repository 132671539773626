(function() {

  // If this component does not exist on the page, abort.
  if (!document.querySelector('.about-circularity')) {
    return;
  }

  // Fetch relevant elements and collections.
  const details = document.querySelectorAll(".about-circularity details");
  const closes = document.querySelectorAll(".about-circularity__disclosure__details__close");

  // Add click listeners to introduce accordion behavior.
  details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", e => {
      e.stopPropagation();

      // Close all the details that are not targetDetail.
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.removeAttribute("open");
        }
      });
    });
  });

  // Add click listener to close details via dedicated button.
  closes.forEach((targetClose) => {
    targetClose.addEventListener("click", e => {
      e.target.parentNode.parentNode.removeAttribute("open");
    });
  });

  document.addEventListener("keyup", e => {
    if (e.key === "Escape" || e.key === "Esc") {
      details.forEach((detail) => {
        detail.removeAttribute("open");
      });
    }
  });

  document.addEventListener("click", e => {
    details.forEach((detail) => {
      detail.removeAttribute("open");
    });
  });

})();
