(function() {

  // If this component does not exist on the page, abort.
  if (!document.querySelector('.about-image-flips')) {
    return;
  }

  // Fetch relevant elements and collections.
  const details = document.querySelectorAll(".about-image-flips details");

  // Add click listeners to introduce accordion behavior.
  details.forEach((targetDetail) => {
    targetDetail.addEventListener("click", e => {
      e.stopPropagation();

      // Close all the details that are not targetDetail.
      details.forEach((detail) => {
        if (detail !== targetDetail) {
          detail.removeAttribute("open");
        }
      });
    });
  });
})();
