(function() {

  // If this component does not exist on the page, abort.
  if (!document.querySelector('.about-scrolly')) {
    return;
  }

  const IS_DEBUG = document.querySelector('body').classList.contains('debug-enabled');

  var scrollers = {};
  const about_sources = document.querySelector("#sources");
  const about_sources_tabs = document.querySelector("#wdngcf");
  const about_sources_tablist = document.querySelector("[data-step=\"el-wdngcf-tablist\"]");
  const about_chapter_wdngcf = document.querySelector("[data-step=\"ch-where-does-ng-come-from\"]");
  const about_step_imageflip = document.querySelector("#footwear-apparel-image-flips");

  const about_imageflip_disclosures = document.querySelectorAll('.about-image-flip__item');
  const scrollies = document.querySelectorAll(".about-scrolly");
  const scrolly_background = document.querySelector('.about-scrolly__background');
  const scrolly_tint = document.querySelector('.about-scrolly__background__tint');
  const scrolly_tint_init = document.querySelector(scrolly_tint.getAttribute('data-content-init'));

  const scrolly_tint_padding_px = 120;
  const scrolly_stage_letterbox_threshold_px = scrolly_tint_padding_px - 10;
  const scrolly_stage_tablist_threshold_px = 20;
  const tablist_diff_image_flip_px = 168;
  const tablist_separation_px = 20;
  const tablist_sticky_top_mobile = 50;
  const tablist_sticky_top_notebook = 72;
  const breakpoint_notebook_px = 992;
  const breakpoint_min_height_clingy_tablist = 800;

  const stats = document.querySelectorAll('.about-stat__number');

  const wait_dur_debounce = 500;

  var tint_scale = 1.0;
  var tablist_top = 0.0;
  var stage_top = 0.0;
  var stage_bot = 1.0;

  // Initialize inner text of each stat.
  stats.forEach((stat) => {
    if (stat.getAttribute('data-number-start')) {
      stat.innerHTML = stat.getAttribute('data-number-start');
    }
  });

  window.addEventListener('load', function () {

    scrollies.forEach((scrolly) => {

      const scroller = scrollama();

      const scrolly_name = scrolly.getAttribute('data-scrolly-name');
      const scrolly_steps = scrolly.getAttribute('data-scrolly-steps');
      const scrolly_offset = Number.parseFloat(scrolly.getAttribute('data-scrolly-offset'));

      // setup the instance, pass callback functions
      scroller
        .setup({
          step: scrolly_steps,
          offset: scrolly_offset,
          progress: true,
          debug: IS_DEBUG,
        })
        .onStepEnter(response => {
          const e = response.element;
          const name = e.getAttribute('data-step');
          const dir = response.direction === 'down' ? 'forward' : 'backward';
          const tint_type = e.getAttribute('data-tint-type');
          var prox = 'on';

          if (IS_DEBUG) console.log('Enter: ', name, ' — ' + dir + ' (' + prox + ' ' + scrolly_name + ')');

          if (tint_type) {
            const step_content_selector = e.getAttribute('data-step-content');
            const step_content = document.querySelector(step_content_selector);
            configure_stage(tint_type, step_content);
          }

          // Scrollama appears to incorrectly trigger enter and exit events for
          // nested sticky elements.  This will manually determine offset
          // proximity instead of relying on the event.
          if (scrolly_name === 'sources-tablist-top' && name === 'el-wdngcf-tablist') {
            var offset = scrolly_offset;
            var tablist_top = (e.getBoundingClientRect().top / window.innerHeight).toFixed(3);
            var tablist_bot = (e.getBoundingClientRect().bottom / window.innerHeight).toFixed(3);
            prox = (tablist_top < offset) ? 'on' : 'below;'
          }

          // If an image flip trigger has entered, open its corresponding
          // details disclosure element.
          if (e.classList.contains('about-image-flip-trigger')) {

            // If this trigger is intended to reveal the disclosures, do it now.
            if (name === 'reveal-disclosures') {
              scrolly.classList.add('scrolly--disclosures-revealed');
            }

            // Close all disclosures.
            about_imageflip_disclosures.forEach((detail) => {
              detail.removeAttribute('open');
            });

            // And then open the triggered disclosure.
            targetDetail = document.querySelector('.about-image-flip__item[data-flip="' + name + '"]');

            if (targetDetail) {
              targetDetail.setAttribute('open', '');
            }
          }

          // Identify proximity of this entering element to its scroller’s offset
          e.setAttribute('data-prox-' + scrolly_name, prox);

          // Mark this step has having been entered.
          e.classList.add('about-scrolly__step--has-been-entered');
        })
        .onStepExit(response => {
          const e = response.element;
          const name = e.getAttribute('data-step');
          const dir = response.direction === 'down' ? 'forward' : 'backward';
          var prox = (dir === 'forward') ? 'above' : 'below';

          if (IS_DEBUG) console.log('Exit: ', name, ' — ' + dir + ' (' + prox + ' ' + scrolly_name + ')');

          // Scrollama appears to incorrectly trigger enter and exit events for
          // nested sticky elements.  This will manually determine offset
          // proximity instead of relying on the event.
          if (scrolly_name === 'sources-tablist-top' && name === 'el-wdngcf-tablist') {
            var offset = scrolly_offset;
            var tablist_top = (e.getBoundingClientRect().top / window.innerHeight).toFixed(3);
            var tablist_bot = (e.getBoundingClientRect().bottom / window.innerHeight).toFixed(3);
            prox = (tablist_top < offset && tablist_bot < offset) ? 'above' : 'below';
          }

          // Identify proximity of this exiting element to its scroller’s offset
          e.setAttribute('data-prox-' + scrolly_name, prox);

          // If this element belongs to a tabpanel, set the active item on the
          // tablist accordingly.
          if (e.getAttribute('data-tablist-item')) {
            if ((scrolly_name === 'sources-stage-bot' && dir === 'forward') ||
                (scrolly_name === 'sources-stage-top' && dir === 'backward')) {
              about_sources_tabs.setAttribute('data-tablist-item-active', e.getAttribute('data-tablist-item'));
            }
          }
        })
        .onStepProgress(response => {
          const e = response.element;
          const name = e.getAttribute('data-step');
          const progress_step = response.progress;

          if (name === 'ch-recycled-stat') {
            const stat = e.querySelector('.about-stat__number');
            const progress_start = parseFloat(stat.getAttribute('data-progress-start'));
            const progress_end = parseFloat(stat.getAttribute('data-progress-end'));
            const progress_diff = progress_end - progress_start;
            const progress_cur = progress_step / progress_diff;
            const number_start = parseFloat(stat.getAttribute('data-number-start'));
            const number_end = parseFloat(stat.getAttribute('data-number-end'));
            const number_diff = number_end - number_start;
            const number_cur = Math.min(number_end, number_start + Math.round(number_diff * progress_cur));

            stat.innerHTML = number_cur;
          }

          // Scrollama appears to incorrectly trigger enter and exit events for
          // nested sticky elements.  This will manually determine offset
          // proximity instead of relying on the event.
          if (scrolly_name === 'sources-tablist-top' && name === 'el-wdngcf-tablist') {
            var offset = scrolly_offset;
            var tablist_top = (e.getBoundingClientRect().top / window.innerHeight).toFixed(3);
            var tablist_bot = (e.getBoundingClientRect().bottom / window.innerHeight).toFixed(3);
            var prox = '';

            if (tablist_top < offset && tablist_bot < offset) {
              prox = 'above';
            } else if (tablist_top > offset && tablist_bot > offset) {
              prox = 'below';
            } else {
              prox = 'on';
            }

            e.setAttribute('data-prox-' + scrolly_name, prox);
          }
        });


      // Keep a reference to this scroller instance.
      scrollers[scrolly_name] = scroller;

      // Resize this Scrollama instace when the window is resized.
      function debounce(func, wait = 100) {
        let timeout;
        return function(...args) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            func.apply(this, args);
          }, wait);
        };
      }

      const debounced_resize = debounce(function() {
        resize_venue(scroller);
      }, wait_dur_debounce);

      window.addEventListener("resize", debounced_resize);

      // Mark scrolly as initialized.
      scrolly.classList.add('about-scrolly--initialized');

      // Massage debug offset
      if (IS_DEBUG && scroller.getId()) {
        var debug_offset = document.querySelector('#scrollama__debug-offset--' + scroller.getId() + ' p');
        var debug_offset_span = debug_offset.querySelector('span').innerHTML;

        debug_offset.innerHTML = '<strong>' + scrolly_name + '</strong> <span>' + debug_offset_span + '</span>';
      }
    });

    // Initialize the viewing stage.
    configure_stage('letterbox', scrolly_tint_init);

    function resize_venue(scroller) {
      if (IS_DEBUG) console.log('resize_venue');
      scroller.resize();
    }

    function configure_stage(tint_type, step_content) {

      const is_clingy_tablist = (window.innerWidth > breakpoint_notebook_px && window.innerHeight >= breakpoint_min_height_clingy_tablist);

      var stage_letterbox_threshold_scale = scrolly_stage_letterbox_threshold_px / window.innerHeight;
      var stage_tablist_threshold_scale = scrolly_stage_tablist_threshold_px / window.innerHeight;
      var rect_tablist = about_sources_tablist.getBoundingClientRect();
      var top_tablist_scale_mobile = tablist_sticky_top_mobile / window.innerHeight;
      var top_tablist_scale_notebook = tablist_sticky_top_notebook / window.innerHeight;
      var height_tablist_scale = rect_tablist.height / window.innerHeight;
      var tablist_diff_image_flip_scale = tablist_diff_image_flip_px / window.innerHeight;
      var scrolly_tint_padding_scale = scrolly_tint_padding_px / window.innerHeight;
      var tablist_separation_scale = tablist_separation_px / window.innerHeight;

      var tint_scale = 0;
      var stage_scale = 0;
      var stage_top = 0;
      var stage_bot = 0;
      var tablist_top = 0;

      if (tint_type === 'letterbox') {
        const rect_background = scrolly_background.getBoundingClientRect();
        const rect_content = step_content.getBoundingClientRect();
        const height_content = rect_content.height + (scrolly_tint_padding_px * 2);
        tint_scale = Math.min(1, (height_content / rect_background.height));
      } else {
        tint_scale = 1;
      }

      stage_scale = (1 - tint_scale) / 2

      if (is_clingy_tablist) {
        tablist_top = Math.max(0, (0.5 - (height_tablist_scale + tablist_separation_scale + tablist_diff_image_flip_scale + stage_tablist_threshold_scale)));
      } else {
        tablist_top = top_tablist_scale_mobile;
      }

      if (tint_type === 'fullscreen-with-tablist') {
        if (is_clingy_tablist) {
          stage_top = tablist_top + height_tablist_scale + (stage_tablist_threshold_scale * 2);
        } else {
          stage_top = tablist_top + height_tablist_scale + stage_tablist_threshold_scale;
        }
        stage_bot = 1 - stage_scale - stage_tablist_threshold_scale;
      } else {
        stage_top = 0 + stage_scale + stage_letterbox_threshold_scale;
        stage_bot = 1 - stage_scale - stage_letterbox_threshold_scale;
      }

      // Reset stage scroller offsets
      scrollers['sources-tablist-top'].offsetTrigger(tablist_top).resize();
      scrollers['sources-stage-top'].offsetTrigger(stage_top).resize();
      scrollers['sources-stage-bot'].offsetTrigger(stage_bot).resize();

      scrolly_tint.style.transform = 'scale(1, ' + tint_scale + ')';

      about_sources.setAttribute('data-active-tint', tint_type);
    }

  });
})();
